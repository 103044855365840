import * as React from 'react';
import { useUser } from '../shared/Auth/UserContext';
import { Loader } from '../shared/Loader/Loader';

const AuthenticatedApp = React.lazy(() => import('../authenticated-app/AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('../unauthenticated-app/UnauthenticatedApp'));

export function App() {
  const user = useUser();
  return (
    <React.Suspense fallback={<Loader />}>
      {user && user.profile !== null ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}
