import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';
import { RemoveEdgeAction } from '../actions/RemoveEdgeAction';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class RemoveEdgeEpicProvider extends SwitchMapEpicProvider<RemoveEdgeAction> {
  public type = GraphViewActionTypes.GRAPH_EDGE_REMOVAL_START;

  protected async process(action: RemoveEdgeAction) {
    try {
      await BackendClient.delete(`/api/graph/schema/${action.schemaId}/graph/${action.graphId}/edge/${action.edgeId}`);
    } catch (e) {}
    return new FetchGraphSchemaStartAction(action.schemaId);
  }
}
