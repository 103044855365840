export const emptyActionMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action === void 0) {
    action = {
      type: '__DUMMY',
    };
  } else if (!action.type) {
    action.type = '__DUMMY';
  }
  return next(action);
};
