import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { FetchGraphListSuccessAction } from '../actions/FetchGraphListSuccessAction';

export class FetchGraphListSuccessActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.FETCH_GRAPH_LIST_SUCCESS);
  }

  handle(state: IGraphViewState, action: FetchGraphListSuccessAction) {
    state.schemaList = action.schemaList;
    state.isFetching = false;
    state.fetchError = null;
    return { ...state };
  }
}
