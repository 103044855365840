import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';
import { BackendClient } from '../../shared/BackendClient/BackendClient';
import { PasteStartAction } from '../actions/PasteStartAction';

export class PasteEpicProvider extends SwitchMapEpicProvider<PasteStartAction> {
  static isActive = false;
  public type = GraphViewActionTypes.PASTE_START;

  protected async process(action: PasteStartAction) {
    if (PasteEpicProvider.isActive) {
      return;
    }
    try {
      PasteEpicProvider.isActive = true;
      const url = `/api/graph/schema/${action.schemaId}/graph/${action.graphId}/paste`;
      await BackendClient.post(url, {
        nodes: action.nodes,
        edges: action.edges,
      });
    } catch (e) {
    } finally {
      PasteEpicProvider.isActive = false;
    }
    return new FetchGraphSchemaStartAction(action.schemaId);
  }
}
