import { IGraphViewState } from './IGraphViewState';

export const INITIAL_GRAPH_VIEW_STATE: IGraphViewState = {
  schemaList: null,
  isFetching: false,
  schema: null,
  fetchError: null,
  schemaId: null,
  layoutType: 'tree',
};
