import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { IGraphSchema } from '../data/IGraphSchema.interface';
import { GraphNodePositionChangeStartAction } from '../actions/GraphNodePositionChangeStartAction';

export class GraphNodePositionChangeStartActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.GRAPH_NODE_POSITION_CHANGE_START);
  }

  handle(state: IGraphViewState, action: GraphNodePositionChangeStartAction) {
    return {
      ...state,
      schema: this.applyNodePositionToSchema(state.schema, action.nodeId, action.position),
    };
  }

  applyNodePositionToSchema(schema: IGraphSchema | null, nodeId: string, position: { x: number; y: number }) {
    if (!schema) {
      return null;
    }
    const node = schema.nodes.find((n) => n.baseProperties.id === nodeId);
    if (!node) {
      return schema;
    }
    node.baseProperties.x = position.x;
    node.baseProperties.y = position.y;
    return JSON.parse(JSON.stringify(schema));
  }
}
