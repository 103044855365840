import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';

export class FetchGraphSchemaStartActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.FETCH_GRAPH_SCHEMA_START);
  }

  handle(state: IGraphViewState, action: FetchGraphSchemaStartAction) {
    state.schemaId = action.schemaId;
    state.fetchError = null;
    state.isFetching = true;
    return { ...state };
  }
}
