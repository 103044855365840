import axios from 'axios';

export interface IUserProfile {
  id: string;
  provider: string;
  displayName: string;
  email: string;
  photo: string;
}

export class User {
  public profile: IUserProfile | null = null;
  public isProfileLoaded = false;

  async loadProfile() {
    const { data } = await axios.get(`/api/user`);
    this.profile = data;
    this.isProfileLoaded = true;
  }
}
