import IPlaygroundState from '../data/IPlaygroundState.interface';
import { Reducer } from 'react';
import PlaygroundActions from '../actions/PlaygroundActions.type';
import PlaygroundActionTypes from '../actions/PlaygroundActionTypes.enum';

const initialState: IPlaygroundState = {
  playgrounds: {},
};

const PlaygroundReducer: Reducer<IPlaygroundState, PlaygroundActions> = (
  state: IPlaygroundState = initialState,
  action: PlaygroundActions,
) => {
  /* TODO */
  switch (action.type) {
    case PlaygroundActionTypes.CREATE_PLAYGROUND: {
      state.playgrounds[action.key] = {
        fetchError: null,
        inputValue: action.initialValue,
        isFetching: false,
        response: null,
      };
      return {
        playgrounds: JSON.parse(JSON.stringify(state.playgrounds)),
      };
    }
    case PlaygroundActionTypes.UPDATE_PLAYGROUND_INPUT: {
      state.playgrounds[action.key].inputValue = action.value;
      return {
        playgrounds: JSON.parse(JSON.stringify(state.playgrounds)),
      };
    }
    case PlaygroundActionTypes.FETCH_PLAYGROUND_QUERY_START: {
      state.playgrounds[action.graphId].response = null;
      state.playgrounds[action.graphId].isFetching = true;
      state.playgrounds[action.graphId].fetchError = null;
      return {
        playgrounds: JSON.parse(JSON.stringify(state.playgrounds)),
      };
    }
    case PlaygroundActionTypes.FETCH_PLAYGROUND_QUERY_FAILED: {
      state.playgrounds[action.key].isFetching = false;
      state.playgrounds[action.key].fetchError = action.error;
      return {
        playgrounds: JSON.parse(JSON.stringify(state.playgrounds)),
      };
    }
    case PlaygroundActionTypes.FETCH_PLAYGROUND_QUERY_SUCCESS: {
      state.playgrounds[action.key].isFetching = false;
      state.playgrounds[action.key].response = action.response;
      return {
        playgrounds: JSON.parse(JSON.stringify(state.playgrounds)),
      };
    }
  }
  return state;
};

export default PlaygroundReducer;
