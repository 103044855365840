import * as React from 'react';
import { useAuth } from './AuthContext';
import { IUserProfile } from './User';

const UserContext = React.createContext<{ profile: IUserProfile | null }>({
  profile: null,
});

export const UserProvider = (props: any) => <UserContext.Provider value={useAuth().user} {...props} />;
export const useUser = () => React.useContext(UserContext);
