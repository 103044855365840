import {
  ICreatePlaygroundAction,
  IFetchPlaygroundQueryFailed,
  IFetchPlaygroundQueryStart,
  IFetchPlaygroundQuerySuccess,
  IUpdatePlaygroundInputAction,
} from './PlaygroundActions.interfaces';
import PlaygroundActionTypes from './PlaygroundActionTypes.enum';

export const createPlaygroundActionCreator = (key: string, initialValue: object): ICreatePlaygroundAction => ({
  key,
  initialValue,
  type: PlaygroundActionTypes.CREATE_PLAYGROUND,
});

export const updatePlaygroundInputActionCreator = (
  key: string,
  value: { [key: string]: any },
): IUpdatePlaygroundInputAction => ({
  type: PlaygroundActionTypes.UPDATE_PLAYGROUND_INPUT,
  key,
  value,
});

export const fetchPlaygroundQueryStartCreator = (
  schemaId: string,
  graphId: string,
  input: object,
): IFetchPlaygroundQueryStart => ({
  schemaId,
  graphId,
  input,
  type: PlaygroundActionTypes.FETCH_PLAYGROUND_QUERY_START,
});

export const fetchPlaygroundQuerySuccessCreator = (key: string, response: any): IFetchPlaygroundQuerySuccess => ({
  type: PlaygroundActionTypes.FETCH_PLAYGROUND_QUERY_SUCCESS,
  key,
  response,
});

export const fetchPlaygroundQueryFailedCreator = (key: string, error: Error | string): IFetchPlaygroundQueryFailed => ({
  error,
  key,
  type: PlaygroundActionTypes.FETCH_PLAYGROUND_QUERY_FAILED,
});
