import { Reducer } from 'react';
import { AnyAction } from 'redux';
import { ActionHandler } from './ActionHandler`.class';

export abstract class ReducerProvider<TState> {
  private handlers: { [key: string]: (state: TState, action: AnyAction) => TState } = {};

  constructor(protected initialState: TState) {
    this.setupHandlers();
  }

  abstract setupHandlers(): void;

  addHandler<TActionHandler extends ActionHandler<TState>>(ctor: { new (): TActionHandler }) {
    const value = new ctor();
    const type = value.type;
    this.handlers[type] = (state: TState, action: AnyAction) => value.handle(state, action);
  }

  toReducer(): Reducer<TState, AnyAction> {
    return (prevState = this.initialState, action: AnyAction) => {
      if (action && action.type in this.handlers) {
        return this.handlers[action.type](prevState, action);
      }
      return prevState;
    };
  }
}
