import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';
import { RemoveNodeAction } from '../actions/RemoveNodeAction';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class RemoveNodeEpicProvider extends SwitchMapEpicProvider<RemoveNodeAction> {
  public type = GraphViewActionTypes.GRAPH_NODE_REMOVAL_START;

  protected async process(action: RemoveNodeAction) {
    try {
      const url = `/api/graph/schema/${action.schemaId}/graph/${action.graphId}/node/${action.nodeId}`;
      await BackendClient.delete(url);
    } catch (e) {}
    return new FetchGraphSchemaStartAction(action.schemaId);
  }
}
