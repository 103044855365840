import { Action, AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { share, switchMap } from 'rxjs/operators';
import { EpicProvider } from './EpicProvider`';

export abstract class SwitchMapEpicProvider<T extends Action<string>> extends EpicProvider<T> {
  protected abstract async process(action: T): Promise<AnyAction | void>;

  toEpic(): Epic<T, any> {
    return (action$: ActionsObservable<T>) =>
      action$.pipe(
        ofType(this.type),
        switchMap((action) => this.process(action)),
        share(),
      );
  }
}
