import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { ChangeLayoutTypeAction } from '../actions/ChangeLayoutTypeAction';
import { IGraphViewState } from '../IGraphViewState';

export class ChangeLayoutTypeActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.CHANGE_LAYOUT_TYPE);
  }

  handle(state: IGraphViewState, action: ChangeLayoutTypeAction): IGraphViewState {
    return {
      ...state,
      layoutType: action.layoutType,
    };
  }
}
