import { ReducerProvider } from '../shared/ReducerProvider`.class';
import { IGraphViewState } from './IGraphViewState';
import { FetchGraphListSuccessActionHandler } from './action-handlers/FetchGraphListSuccessActionHandler';
import { FetchGraphSchemaFailureActionHandler } from './action-handlers/FetchGraphSchemaFailureActionHandler';
import { FetchGraphSchemaStartActionHandler } from './action-handlers/FetchGraphSchemaStartActionHandler';
import { FetchGraphSchemaSuccessActionHandler } from './action-handlers/FetchGraphSchemaSuccessActionHandler';
import { GraphNodePositionChangeStartActionHandler } from './action-handlers/GraphNodePositionChangeStartActionHandler';
import { GraphNodePropertiesChangeStartActionHandler } from './action-handlers/GraphNodePropertiesChangeStartActionHandler';
import { FetchGraphListStartActionHandler } from './action-handlers/FetchGraphListStartActionHandler';
import { ChangeLayoutTypeActionHandler } from './action-handlers/ChangeLayoutTypeActionHandler';
import { FetchGraphListFailureActionHandler } from './action-handlers/FetchGraphListFailureActionHandler';

export class GraphViewReducerProvider extends ReducerProvider<IGraphViewState> {
  setupHandlers() {
    this.addHandler(FetchGraphListStartActionHandler);
    this.addHandler(FetchGraphListSuccessActionHandler);
    this.addHandler(FetchGraphListFailureActionHandler);
    this.addHandler(FetchGraphSchemaFailureActionHandler);
    this.addHandler(FetchGraphSchemaStartActionHandler);
    this.addHandler(FetchGraphSchemaSuccessActionHandler);
    this.addHandler(ChangeLayoutTypeActionHandler);
    this.addHandler(GraphNodePropertiesChangeStartActionHandler);
    this.addHandler(GraphNodePositionChangeStartActionHandler);
  }
}
