import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';
import { CreateNodeAction } from '../actions/CreateNodeAction';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class CreateNodeEpicProvider extends SwitchMapEpicProvider<CreateNodeAction> {
  public type = GraphViewActionTypes.GRAPH_NODE_CREATION_START;

  protected async process(action: CreateNodeAction) {
    try {
      const url = `/api/graph/schema/${action.schemaId}/graph/${action.graphId}/node`;
      const body = {
        nodeType: action.nodeType,
        position: action.position,
        properties: action.properties,
      };
      await BackendClient.post(url, body);
    } catch (e) {}

    return new FetchGraphSchemaStartAction(action.schemaId);
  }
}
