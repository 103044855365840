import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';
import { CreateEdgeAction } from '../actions/CreateEdgeAction';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class CreateEdgeEpicProvider extends SwitchMapEpicProvider<CreateEdgeAction> {
  public type = GraphViewActionTypes.GRAPH_EDGE_CREATION_START;

  protected async process(action: CreateEdgeAction) {
    try {
      const url = `/api/graph/schema/${action.schemaId}/graph/${action.graphId}/edge`;
      const body = {
        source: action.source,
        destination: action.destination,
      };
      await BackendClient.post(url, body);
    } catch (e) {}

    return new FetchGraphSchemaStartAction(action.schemaId);
  }
}
