import { GraphNodePositionChangeStartAction } from '../actions/GraphNodePositionChangeStartAction';
import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class UpdateNodePositionEpicProvider extends SwitchMapEpicProvider<GraphNodePositionChangeStartAction> {
  public type = GraphViewActionTypes.GRAPH_NODE_POSITION_CHANGE_START;

  protected async process(action: GraphNodePositionChangeStartAction) {
    try {
      const url = `/api/graph/schema/${action.schemaId}/graph/${action.graphId}/node/${action.nodeId}/position`;
      await BackendClient.post(url, action.position);
    } catch (e) {}
  }
}
