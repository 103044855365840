import * as React from 'react';
import * as ReactDOM from 'react-dom';

import configureStore from './store/Store';
import Root from './root/Root';
import './global.css';

// Generate the store
const store = configureStore();

// Render the app
ReactDOM.render(<Root store={store} />, document.getElementById('root') as HTMLElement);
