import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { FetchGraphSchemaFailureAction } from '../actions/FetchGraphSchemaFailureAction';

export class FetchGraphSchemaFailureActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.FETCH_GRAPH_LIST_FAILURE);
  }

  handle(state: IGraphViewState, action: FetchGraphSchemaFailureAction) {
    state.fetchError = action.error;
    state.schema = null;
    state.isFetching = false;
    return { ...state };
  }
}
