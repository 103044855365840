import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { FetchGraphListFailureAction } from '../actions/FetchGraphListFailureAction';
import { GraphViewActionHandler } from './GraphViewActionHandler.class';

export class FetchGraphListFailureActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.FETCH_GRAPH_LIST_FAILURE);
  }

  handle(state: IGraphViewState, action: FetchGraphListFailureAction) {
    state.fetchError = action.error;
    state.schemaList = null;
    state.isFetching = false;
    return { ...state };
  }
}
