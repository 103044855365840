import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { FetchGraphListStartAction } from '../actions/FetchGraphListStartAction';

export class FetchGraphListStartActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.FETCH_GRAPH_LIST_START);
  }

  handle(state: IGraphViewState, action: FetchGraphListStartAction) {
    state.fetchError = null;
    state.schemaList = null;
    state.isFetching = true;
    return { ...state };
  }
}
