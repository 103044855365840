import IAppState from './IAppState.interface';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import PlaygroundReducer from '../graph-view/action-handlers/PlaygroundReducer';
import playEpic from '../graph-view/epics/play';
import { GRAPH_VIEW_EPICS } from '../graph-view/graphViewEpics.const';
import { GRAPH_VIEW_REDUCER } from '../graph-view/graphViewReducer.const';
import { classInstanceActionMiddleware } from '../shared/classInstanceActionMiddleware';
import { emptyActionMiddleware } from '../shared/emptyActionMiddleware';

/* Epics */
const rootEpic = combineEpics(GRAPH_VIEW_EPICS, playEpic);
const epicMiddleware = createEpicMiddleware();

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* Reducers */
const rootReducer = combineReducers({
  graphView: GRAPH_VIEW_REDUCER,
  playground: PlaygroundReducer,
});

export default function configureStore(): Store<IAppState, any> {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(classInstanceActionMiddleware, epicMiddleware, emptyActionMiddleware)),
  );
  epicMiddleware.run(rootEpic);
  return store;
}
