import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { FetchGraphListStartAction } from '../actions/FetchGraphListStartAction';
import { FetchGraphListSuccessAction } from '../actions/FetchGraphListSuccessAction';
import { FetchGraphListFailureAction } from '../actions/FetchGraphListFailureAction';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { BackendClient } from '../../shared/BackendClient/BackendClient';
import { GraphSchemaFolders, IGraphSchema } from '../data/IGraphSchema.interface';

export class FetchGraphListEpicProvider extends SwitchMapEpicProvider<FetchGraphListStartAction> {
  public type = GraphViewActionTypes.FETCH_GRAPH_LIST_START;

  protected async process(action: FetchGraphListStartAction) {
    try {
      const { data } = await BackendClient.get<IGraphSchema[]>(`/api/graph/schema`);
      const folders: GraphSchemaFolders = {};
      data.forEach((schema) => {
        const folderIndex = schema.title.lastIndexOf('/');
        const folder = folderIndex > -1 ? schema.title.substr(0, folderIndex) : 'Other';
        schema.title = schema.title.substr(folderIndex + 1);
        if (!(folder in folders)) {
          folders[folder] = [];
        }
        folders[folder].push(schema);
      });
      return new FetchGraphListSuccessAction(folders);
    } catch (e) {
      return new FetchGraphListFailureAction(e);
    }
  }
}
