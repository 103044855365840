import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { FetchGraphSchemaSuccessAction } from '../actions/FetchGraphSchemaSuccessAction';

export class FetchGraphSchemaSuccessActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.FETCH_GRAPH_SCHEMA_SUCCESS);
  }

  handle(state: IGraphViewState, action: FetchGraphSchemaSuccessAction) {
    state.schema = action.schema;
    state.isFetching = false;
    state.fetchError = null;
    return { ...state };
  }
}
