import * as React from 'react';
import { AuthProvider } from '../shared/Auth/AuthContext';
import { UserProvider } from '../shared/Auth/UserContext';
// @ts-ignore
export default function AppProviders({ children }) {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  );
}
