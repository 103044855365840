import { GraphViewActionHandler } from './GraphViewActionHandler.class';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { IGraphViewState } from '../IGraphViewState';
import { IGraphSchema, GraphNodeProperties } from '../data/IGraphSchema.interface';
import { GraphNodePropertiesChangeStartAction } from '../actions/GraphNodePropertiesChangeStartAction';

export class GraphNodePropertiesChangeStartActionHandler extends GraphViewActionHandler {
  constructor() {
    super(GraphViewActionTypes.GRAPH_NODE_PROPERTIES_UPDATE_START);
  }

  handle(state: IGraphViewState, action: GraphNodePropertiesChangeStartAction) {
    return {
      ...state,
      schema: this.applyNodePropertiesToSchema(state.schema, action.nodeId, action.properties),
    };
  }

  applyNodePropertiesToSchema(schema: IGraphSchema | null, nodeId: string, properties: GraphNodeProperties) {
    if (!schema) {
      return null;
    }
    const node = schema.nodes.find((n) => n.baseProperties.id === nodeId) as any;
    if (!node || !node.properties) {
      return schema;
    }
    node.properties = properties;
    return JSON.parse(JSON.stringify(schema));
  }
}
