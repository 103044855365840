import * as React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import IAppState from '../store/IAppState.interface';
import { App } from '../app/App';
import AppProviders from '../app-providers/AppProviders';

// Create interface for Props
interface IProps {
  store: Store<IAppState>;
}

const Root: React.FC<IProps> = ({ store }) => (
  <Provider store={store}>
    <AppProviders>
      <App />
    </AppProviders>
  </Provider>
);

export default Root;
